<template>
  <div>
    <b-row>
      <b-col xl="6">
        <card-analytic-status-dinas :analytics="statusDinas" />
      </b-col>
      <b-col xl="3">
        <card-keaktifan-status :analytics="keaktifan" />
      </b-col>
      <b-col xl="3">
        <card-login-status :analytics="statusLogin" />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <card-pangkat :pangkat="pangkat" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import CardPangkat from "./components/CardPangkat";
import { personil } from '@/actions'
import CardAnalyticStatusDinas from "./components/CardAnalyticStatusDinas";
import CardLoginStatus from "./components/CardLoginStatus";
import CardKeaktifanStatus from "./components/CardKeaktifanStatus";
export default {
  components: {
    BRow,
    BCol,
    CardPangkat,
    CardAnalyticStatusDinas,
    CardLoginStatus,
    CardKeaktifanStatus,
  },
  data() {
    return {
      pangkat: [],
      statusDinas: [],
      statusLogin: {},
      keaktifan: {},
    }
  },
  mounted() {
    this.fetchDataPersonil();
  },
  methods: {
    fetchDataPersonil() {
      personil()
        .then((data) => {
          const { pangkat, keaktifan, status_dinas, status_login } = data
          this.pangkat = pangkat
          this.keaktifan = keaktifan
          this.statusDinas = status_dinas
          this.statusLogin = status_login
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
}
</script>

<style>

</style>