<template>
  <b-card no-body class="card-custom">
    <b-card-header>
      <b-card-title>Status Dinas Personil Beat</b-card-title>
    </b-card-header>

    <b-card-body>
      <b-row class="mt-5">
        <b-col xl="6">
          <!-- apex chart -->
          <vue-apex-charts
            type="donut"
            height="320"
            class="mb-1"
            :options="statusDinasChart.chartOptions"
            :series="statusDinasChart.series"
          />
        </b-col>
        <b-col xl="6">
          <!-- list group -->
          <div class="pt-25">
            <div
              v-for="(data, index) in analytics"
              :key="index"
              class="d-flex justify-content-between"
              :class="index === Object.keys(analytics).length - 1 ? '' : 'mb-1'"
            >
              <div class="series-info">
                <feather-icon
                  icon="TargetIcon"
                  size="16"
                  class="mr-50"
                  :color="data.color"
                />
                <span class="font-weight-bolder">{{ data.kegiatan }}</span>
              </div>
              <span>{{ data.jumlah }}</span>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BDropdown,
  BDropdownItem,
  BCardBody,
  BRow,
  BCol,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardBody,
    VueApexCharts,
  },
  props: {
    analytics: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      chartData: {},
      statusDinasChart: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          stroke: { width: 0 },
          colors: [
            "#ff9f43",
            "#28c76f",
            "#00cfe8",
            "#7367f0",
            "#ea5455",
            "#F1C40F",
            "#4a0503",
            "#425a0e",
            "#2505c5",
            "#c96d73",
          ],
        },
      },
    };
  },
  watch: {
    analytics: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.setChartData();
          this.setListData();
        }
      },
    },
  },
  methods: {
    setChartData() {
      if (this.analytics.length > 0) {
        this.analytics.map((e) => {
          this.statusDinasChart.series.push(e.jumlah);
          this.statusDinasChart.chartOptions.labels.push(e.kegiatan);
        });
      }
    },
    setListData() {
      if (this.analytics.length > 0) {
        for (let i = 0; i < this.analytics.length; i++) {
          const objData = this.analytics[i];
          objData["color"] = this.statusDinasChart.chartOptions.colors[i];
          // this.analytics[i].push({
          //   color: this.statusDinasChart.chartOptions.colors[i],
          // });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-custom {
  min-height: 485px;
}
</style>
