import { DATA_DARURAT, DATA_DARURAT_CHART_STATUS, DATA_DARURAT_CHART_STATISTIK } from './const'
import client from './client'
import { formatDate } from './shared'

export const darurat = async () => {
  const { data } = await client.get(DATA_DARURAT)
  return data
}

export const daruratChartStatistik = async (start, end) => {
  const startdate = formatDate(start), enddate = formatDate(end)
  const { data } = await client.post(DATA_DARURAT_CHART_STATISTIK, { startdate, enddate })
  return data
}

export const daruratChartStatus = async (start, end) => {
  const startdate = formatDate(start), enddate = formatDate(end)
  const { data } = await client.post(DATA_DARURAT_CHART_STATUS, { startdate, enddate })
  return data
}
