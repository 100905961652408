<template>
  <b-card no-body class="card-employee-task">
    <b-card-header v-if="type === 'new'">
      <b-card-title>Kegiatan Terbaru</b-card-title>
    </b-card-header>
    <b-card-header v-else>
      <b-card-title>Pengirim Terbanyak</b-card-title>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="(personil, index) in personils"
        :key="index"
        class="employee-task d-flex justify-content-between align-items-center"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-avatar
              rounded
              size="42"
              :src="personil.photos === '' ? null : personil.photos"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="mb-0">{{ personil.pangkat }} {{ personil.nama }}</h6>
            <small>{{ personil.nrp }} - {{ personil.kesatuan }} </small>
            <div>{{ personil.judul }}</div>
          </b-media-body>
        </b-media>
        <div v-if="type === 'many'" class="d-flex">
            <b-badge class="mr-75" variant="primary">
              <small>{{ personil.jumlah }} Kegiatan</small>
            </b-badge>
          </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BBadge,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    personils: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
/* eslint-disable global-require */
</script>
