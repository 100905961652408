<template>
  <div>
    <!-- Stats Card Vertical -->
    <statistic-card :statistics="statistics" />
    <!-- Stats Card Vertical -->
    <b-row>
      <b-col xl="6">
        <statistic-chart :analytics="dataStatisticHarian" />
      </b-col>
      <b-col xl="6">
        <card-analytic-status-dinas
          :analytics="chartStatusDinas"
          v-if="chartStatusDinas"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="6">
        <card-statistics-kondisi-personil :analytics="kondisiPersonil" />
      </b-col>
      <b-col xl="6">
        <card-statistics-lokasi-bekerja :analytics="lokasiKerja" />
      </b-col>
    </b-row>
    <!-- Card List Best Personil -->
    <b-row>
      <b-col xl="4">
        <card-list-best-personil type="personilAktif" />
      </b-col>
      <b-col xl="4">
        <card-list-best-personil type="personilBeat" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { home } from "@/actions";
import { BRow, BCol } from "bootstrap-vue";
import StatisticCard from "./components/StatisticCard";
import StatisticChart from "./components/StatisticChart";
import CardListBestPersonil from "./components/CardListBestPersonil";
import CardAnalyticStatusDinas from "./components/CardAnalyticStatusDinas";
import CardStatisticsKondisiPersonil from "./components/CardStatisticsKondisiPersonil";
import CardStatisticsLokasiBekerja from "./components/CardStatisticsLokasiBekerja";

export default {
  components: {
    BRow,
    BCol,
    StatisticCard,
    StatisticChart,
    CardListBestPersonil,
    CardAnalyticStatusDinas,
    CardStatisticsKondisiPersonil,
    CardStatisticsLokasiBekerja,
  },
  data() {
    return {
      chartStatusDinas: [],
      kondisiPersonil: [],
      lokasiKerja: [],
      dataStatisticHarian: {},
      statistics: {
        darurat: 0,
        jajaran: 0,
        kegiatan: 0,
        kejadian: 0,
        kesatuan: 0,
        pengaduan: 0,
        personil: 0,
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      await home().then((response) => {
        this.statistics = { ...response.data.summary };
        this.chartStatusDinas = response.data.charts.beat_status_dinas;
        this.kondisiPersonil = Object.values(response.data.charts.kondisi_personil);
        this.lokasiKerja = Object.values(response.data.charts.lokasi_kerja);
        this.dataStatisticHarian = response.data.charts.harian;
      });
    },
  },
};
</script>
