<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Statistik ({{ formatTanggal(statistikTanggal[0]) }} s/d
          {{ formatTanggal(statistikTanggal[1]) }})
        </b-card-title>
        <b-card-sub-title>Kegiatan</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <date-picker
          v-model="statistikTanggal"
          range
          class="custom-date"
          :clearable="false"
          @change="fetchChartStatistik"
        ></date-picker>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        type="area"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BCardTitle,
  BCardSubTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { kegiatanChartStatistik } from "@/actions";
import { format, formatISO, parseISO, add, sub, startOfMonth } from "date-fns";
import id from "date-fns/locale/id";

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  data() {
    return {
      dataXaxis: [],
      dataYaxis: [],
      statistikTanggal: [sub(new Date(), { days: 10 }), new Date()],
    };
  },
  computed: {
    // compute series data from you API results
    series: ({ dataXaxis }) => {
      const data = [
        {
          name: "Kegiatan",
          data: dataXaxis,
        },
      ];
      return data;
    },
    chartOptions: ({ dataYaxis }) => {
      const data = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: false,
          curve: "straight",
        },
        legend: {
          show: true,
          position: "top",
          horizontalAlign: "left",
          fontSize: "14px",
          fontFamily: "Montserrat",
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        xaxis: {
          categories: dataYaxis,
        },
        yaxis: {},
        fill: {
          opacity: 1,
          type: "solid",
        },
        tooltip: {
          shared: false,
        },
        colors: ["#FFF3E7"],
      };
      return data;
    },
  },
  created() {
    this.fetchChartStatistik();
  },
  methods: {
    async fetchChartStatistik() {
      const startDate = this.statistikTanggal[0],
        endDate = this.statistikTanggal[1];
      await kegiatanChartStatistik(startDate, endDate)
        .then((response) => {
          const { data, label } = response;
          this.dataXaxis = data;
          this.dataYaxis = label;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    formatTanggal(tanggal) {
      return format(parseISO(formatISO(tanggal)), "d MMMM yyyy", {
        locale: id,
      });
    },
  },
};
</script>
