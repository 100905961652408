<template>
  <b-card no-body class="card-employee-task">
    <b-card-header v-if="type === 'personilBeat'">
      <b-card-title>Personil Beat paling aktif</b-card-title>
      <date-picker
        v-model="personilAktifTanggal"
        range
        class="custom-date"
        :clearable="false"
        @change="getData"
      ></date-picker>
    </b-card-header>
    <b-card-header v-else>
      <b-card-title>Personil paling aktif</b-card-title>
      <date-picker
        v-model="personilBeatAktifTanggal"
        range
        class="custom-date"
        :clearable="false"
        @change="getDataByBeat"
      ></date-picker>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="(personil, index) in personils"
        :key="index"
        class="employee-task d-flex justify-content-between align-items-center"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-avatar
              rounded
              size="42"
              :src="personil.photos === '' ? null : personil.photos"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="mb-0">
              {{ personil.nama }}
            </h6>
            <small
              >{{ personil.nrp }} - {{ personil.kesatuan }}
              <span v-if="type === 'personilBeat'">
                - {{ personil.beat }}</span
              ></small
            >
          </b-media-body>
        </b-media>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { personilAktif, personilBeatAktif } from "@/actions";
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { endOfMonth, startOfMonth } from "date-fns";
/* eslint-disable global-require */
const $trackBgColor = "#e9ecef";
export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      personilAktifTanggal: [startOfMonth(new Date()), endOfMonth(new Date())],
      personilBeatAktifTanggal: [
        startOfMonth(new Date()),
        endOfMonth(new Date()),
      ],
      personils: [],
    };
  },
  mounted() {
    if (this.type === "personilBeat") {
      this.getDataByBeat();
    } else {
      this.getData();
    }
  },
  methods: {
    async getData() {
      const startDate = this.personilAktifTanggal[0],
        endDate = this.personilAktifTanggal[1];
      try {
        await personilAktif(startDate, endDate).then((response) => {
          this.personils = response;
        });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Gagal ambil data personil aktif",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
    async getDataByBeat() {
      const startDate = this.personilBeatAktifTanggal[0],
        endDate = this.personilBeatAktifTanggal[1];
      try {
        await personilBeatAktif(startDate, endDate).then((response) => {
          this.personils = response;
        });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Gagal ambil data personil beat aktif",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
/* eslint-disable global-require */
</script>
