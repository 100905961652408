<template>
  <div>
    <b-row>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="RadioIcon"
          color="success"
          :statistic="total"
          statistic-title="Total"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="RadioIcon"
          :statistic="bulanIni"
          statistic-title="Bulan Ini"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="RadioIcon"
          color="warning"
          :statistic="mingguIni"
          statistic-title="Minggu Ini"
        />
      </b-col>
      <b-col lg="3" sm="6">
        <statistic-card-horizontal
          icon="RadioIcon"
          color="danger"
          :statistic="hariIni"
          statistic-title="Hari Ini"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="12">
        <chart-darurat />
      </b-col>
    </b-row>
    <b-row>
      <b-col xl="4">
        <pie-chart
          :analytics="chartPie"
          title="Per Status"
          @result="filterDate"
        />
      </b-col>
      <b-col xl="4">
        <card-list-data type="new" :darurat="daruratTerbaru" />
      </b-col>
      <b-col xl="4">
        <card-list-data type="many" :darurat="pengirimTerbanyak" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PieChart from "@core/components/charts/apexChart/PieChart";
import { format, formatISO, parseISO, add, sub, startOfMonth } from "date-fns";
import id from "date-fns/locale/id";
import { darurat, daruratChartStatistik, daruratChartStatus } from "@/actions";
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardHorizontal from "@core/components/statistics-cards/StatisticCardHorizontal.vue";
import CardListData from "./components/CardListData";
import ChartDarurat from "./components/ChartDarurat";
export default {
  components: {
    PieChart,
    BRow,
    BCol,
    StatisticCardHorizontal,
    CardListData,
    ChartDarurat,
  },
  data() {
    return {
      statistikTanggal: [sub(new Date(), { days: 10 }), new Date()],
      total: 0,
      bulanIni: 0,
      mingguIni: 0,
      hariIni: 0,
      daruratTerbaru: [],
      pengirimTerbanyak: [],
      chartPie: {},
    };
  },
  mounted() {
    this.fetchDataDarurat();
    this.fetchJenisDarurat();
  },
  methods: {
    filterDate(data) {
      this.statistikTanggal = data;
      this.fetchJenisDarurat();
    },
    fetchDataDarurat() {
      darurat()
        .then((data) => {
          const { summary, darurat_terbaru, pengirim_terbanyak } = data;
          const { total, bulanIni, mingguIni, hariIni } = summary;
          this.total = total;
          this.bulanIni = bulanIni;
          this.mingguIni = mingguIni;
          this.hariIni = hariIni;

          this.daruratTerbaru = darurat_terbaru;
          this.pengirimTerbanyak = pengirim_terbanyak;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchJenisDarurat() {
      const startDate = this.statistikTanggal[0],
        endDate = this.statistikTanggal[1];
      daruratChartStatus(startDate, endDate).then((data) => {
        const labels = Object.keys(data);
        const rawData = Object.values(data);

        const arrData = [];
        labels.forEach(function(v, i) {
          let obj = {};
          obj.name = v;
          obj.value = rawData[i];
          arrData.push(obj);
        });

        this.chartPie = {
          statistikTanggal: this.statistikTanggal,
          type: "pie",
          arrData: arrData,
          series: rawData,
          chartOptions: {
            chart: {
              toolbar: {
                show: false,
              },
            },
            labels: labels,
            dataLabels: {
              enabled: false,
            },
            legend: { show: false },
            stroke: {
              width: 4,
            },
            colors: [
              "#ff9f43",
              "#28c76f",
              "#00cfe8",
              "#7367f0",
              "#ea5455",
              "#F1C40F",
              "#4a0503",
              "#425a0e",
              "#2505c5",
              "#c96d73",
            ],
          },
        };
      });
    },
  },
};
</script>

<style></style>
