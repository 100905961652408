import { DATA_KEJADIAN, DATA_KEJADIAN_CHART_STATUS, DATA_KEJADIAN_CHART_STATISTIK } from './const'
import client from './client'
import { formatDate } from './shared'

export const kejadian = async () => {
  const { data } = await client.get(DATA_KEJADIAN)
  return data
}

export const kejadianChartStatistik = async (start, end) => {
  const startdate = formatDate(start), enddate = formatDate(end)
  const { data } = await client.post(DATA_KEJADIAN_CHART_STATISTIK, { startdate, enddate })
  return data
}

export const kejadianChartStatus = async (start, end) => {
  const startdate = formatDate(start), enddate = formatDate(end)
  const { data } = await client.post(DATA_KEJADIAN_CHART_STATUS, { startdate, enddate })
  return data
}

