<template>
  <div>
    <b-row style="justify-content: center;">
      <b-col xl="3">
        <card-jajaran-head
          logo="/assets/kesatuan/pimpinan.png"
          title="POLDA KALIMANTAN TIMUR"
        />
      </b-col>
    </b-row>
    <b-row class="mt-5">
      <b-col v-for="(item, index) in jajaran" :key="index" xl="4">
        <card-jajaran
          icon="UsersIcon"
          :item="item"
          color="info"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import CardJajaran from "@core/components/statistics-cards/CardJajaran.vue";
import CardJajaranHead from "@core/components/statistics-cards/CardJajaranHead.vue";
import { jajaran } from "@/actions";

export default {
  components: {
    BRow,
    BCol,
    CardJajaran,
    CardJajaranHead,
  },
  data() {
    return {
      jajaran: [
        { jajaran: "POLRES BALIKPAPAN", jumlah: 0 },
        { jajaran: "POLRES BERAU", jumlah: 0 },
        { jajaran: "POLRES BONTANG", jumlah: 0 },
        { jajaran: "POLRES KUTAI KARTANEGARA", jumlah: 0 },
        { jajaran: "POLRES KUTAI BARAT", jumlah: 0 },
        { jajaran: "POLRES KUTAI TIMUR", jumlah: 0 },
        { jajaran: "POLRES PASER", jumlah: 0 },
        { jajaran: "POLRES PENAJEM PASER UTARA", jumlah: 0 },
        { jajaran: "POLRES SAMARINDA", jumlah: 0 },
      ],
    };
  },
  methods: {
    getData() {
      jajaran()
        .then((data) => {
          this.jajaran = data;
        })
        .catch((error) => {
          console.log("ERROR JAJARAN : ", error.response || error);
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style></style>
