<template>
  <div>
    <b-card title="Statistik (7 hari terakhir)">
      <app-echart-bar :option-data="option" />
    </b-card>
  </div>
</template>

<script>
import { BCard } from "bootstrap-vue";
import AppEchartBar from "@core/components/charts/echart/AppEchartBar.vue";

export default {
  components: {
    BCard,
    AppEchartBar,
  },
  props: {
    analytics: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      option: {
        xAxis: [
          {
            type: "category",
            data: [],
          },
        ],
        yAxis: [
          {
            type: "value",
            splitLine: { show: false },
          },
        ],
        grid: {
          left: "40px",
          right: "30px",
          bottom: "30px",
        },
        series: [
          {
            name: "Kegiatan",
            type: "bar",
            stack: "advertising",
            data: [220, 232, 201, 334, 290, 230, 220],
            barMaxWidth: "20%",
            barMinWidth: "10px",
          },
          {
            name: "Kejadian",
            type: "bar",
            stack: "advertising",
            data: [220, 232, 201, 234, 190, 130, 210],
            barMaxWidth: "20%",
            barMinWidth: "10px",
          },
          {
            name: "Darurat",
            type: "bar",
            stack: "advertising",
            data: [120, 102, 101, 134, 190, 130, 210],
            barMaxWidth: "20%",
            barMinWidth: "10px",
          },
        ],
      },
    };
  },
  watch: {
    analytics: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.setChartData();
        }
      },
    },
  },
  methods: {
    setChartData() {
      if (this.analytics) {
        this.option.xAxis[0].data = this.analytics.labels;
        this.option.series[0].data = this.analytics.data.kegiatan;
        this.option.series[1].data = this.analytics.data.kejadian;
        this.option.series[2].data = this.analytics.data.darurat;
      }
    },
  },
};
</script>

<style>
.echarts {
  width: 100% !important;
}
</style>
