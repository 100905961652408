import axios from "axios"
import { AUTH_DETAIL, AUTH_SIGNIN } from './const';
import router from '../router'
import client from './client'
import Vue from 'vue'

const user = async () => {
  const { data: { data } } = await client.get(AUTH_DETAIL)

  if (data.jenis_pemilik !== 'kesatuan' && data.jenis_pemilik !== 'admin') {
    Vue.noty.error('Access denied')
    localStorage.removeItem('token')
    return false
  }
  localStorage.setItem('user', JSON.stringify(data))
  return true
}

const login = async ({ username, password }) => {
  try {
    const { data } = await axios.post(AUTH_SIGNIN, {
      username,
      password,
      client_id: clientId,
      client_secret: clientSecret,
      grant_type: 'password'
    })
    localStorage.setItem('token', data.token_type + " " + data.access_token)
    if (await user()) {
      router.replace({name: 'dashboard'})
      Vue.noty.success('Anda berhasil login')
    }
  } catch (error) {
    Vue.noty.error('Username atau password anda salah')
    throw error
  }
}

export default login
