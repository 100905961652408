<template>
  <div>
    <b-row class="match-height">
      <b-col xl="2" md="4" sm="6">
        <div @click="$router.push({ name: 'Satker | Executive' })">
          <statistic-card-vertical
            icon="GridIcon"
            :statistic="formatNumberSeparator(statistics.kesatuan)"
            statistic-title="Satker"
            color="info"
          />
        </div>
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <div @click="$router.push({ name: 'Polres Jajaran | Executive' })">
          <statistic-card-vertical
            color="warning"
            icon="Share2Icon"
            :statistic="formatNumberSeparator(statistics.jajaran)"
            statistic-title="Polres Jajaran"
          />
        </div>
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <div @click="$router.push({ name: 'Personil | Executive' })">
          <statistic-card-vertical
            color="success"
            icon="UsersIcon"
            :statistic="statistics.personil"
            statistic-title="Personil"
          />
        </div>
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <div @click="$router.push({ name: 'Kegiatan | Executive' })">
          <statistic-card-vertical
            color="primary"
            icon="ApertureIcon"
            :statistic="formatNumberSeparator(statistics.kegiatan)"
            statistic-title="Kegiatan"
          />
        </div>
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <div @click="$router.push({ name: 'Kejadian | Executive' })">
          <statistic-card-vertical
            color="danger"
            icon="ZapIcon"
            :statistic="formatNumberSeparator(statistics.kejadian)"
            statistic-title="Kejadian"
          />
        </div>
      </b-col>
      <b-col xl="2" md="4" sm="6">
        <div @click="$router.push({ name: 'Darurat | Executive' })">
          <statistic-card-vertical
            hide-chart
            color="danger"
            icon="RadioIcon"
            :statistic="formatNumberSeparator(statistics.darurat)"
            statistic-title="Darurat"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import StatisticCardVertical from "@core/components/statistics-cards/StatisticCardVertical.vue";
import { formatNumberSeparator } from "@core/mixins/formatSeparator";

export default {
  components: {
    BRow,
    BCol,
    StatisticCardVertical,
  },
  mixins: [formatNumberSeparator],
  props: {
    statistics: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
};
</script>

<style></style>
