import axios from 'axios'
import router from '../router'
import Vue from 'vue'

const defaultOptions = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin' : '*',
    'Access-Control-Allow-Methods': '*',
    'Accept': 'application/json',
  }
}

let instance = axios.create(defaultOptions);

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  config.headers.Authorization = token
  return config
})

instance.interceptors.response.use(
  (response) => {return response},
  (error) => {
    if (error.response) {
      if (error.response.status === 401) {
        localStorage.removeItem('token')
        router.replace({
          name: 'Login'
        })
        Vue.noty.error('Sesi Anda telah habis!\nSilahkan masuk kembali!')
      }
    }
    return error
  })

export default instance
