<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Keaktifan Personil</b-card-title>
    </b-card-header>

    <b-card-body>

      <!-- apex chart -->
      <vue-apex-charts
        type="pie"
        height="250"
        class="mt-2 mb-1"
        :options="chartPie.chartOptions"
        :series="dataAnalytics(analytics.aktif, analytics.pasif)"
      />

      <!-- list group -->
      <div class="pt-25">
        <div
          class="d-flex justify-content-between mb-1"
        >
          <div class="series-info">
            <feather-icon
              icon="TargetIcon"
              size="16"
              class="mr-50"
              color="#28c76f"
            />
            <span class="font-weight-bolder">Aktif</span>
          </div>
          <span>{{ formatNumberSeparator(analytics.aktif) }}</span>
        </div>
        <div
          class="d-flex justify-content-between"
        >
          <div class="series-info">
            <feather-icon
              icon="TargetIcon"
              size="16"
              class="mr-50"
              color="#ea5455"
            />
            <span class="font-weight-bolder">Pasif</span>
          </div>
          <span>{{ formatNumberSeparator(analytics.pasif) }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardHeader, BRow, BCol, BCardText, BCardTitle, BCardBody } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { formatNumberSeparator } from "@core/mixins/formatSeparator";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCardTitle,
    BCol,
    BCardBody,
  },
  mixins: [formatNumberSeparator],
  props: {
    analytics: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      chartPie: {
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: ['Aktif', 'Pasif'],
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.success, $themeColors.danger],
        },
      },
    };
  },
  methods: {
    dataAnalytics(aktif, pasif) {
      if (aktif && pasif) {
        return [aktif, pasif];
      }
      return [0, 0];
    },
  },
};
</script>
