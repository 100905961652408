<template>
  <div>
    <b-row>
      <b-col v-for="(item, index) in kesatuan" :key="index" xl="3">
        <card-satker :item="item" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import { kesatuan } from "@/actions";
import { kesatuanIcons } from "./const";
import CardSatker from '@core/components/statistics-cards/CardSatker.vue'

export default {
  components: {
    BRow,
    BCol,
    CardSatker,
  },
  data() {
    return {
      kesatuan: [],
    };
  },
  methods: {
    getData() {
      kesatuan()
        .then((data) => {
          this.kesatuan = data.map((item) => {
            const satuan = item.kesatuan.replaceAll(" ", "");
            const icon = Object.prototype.hasOwnProperty.call(
              kesatuanIcons,
              satuan
            )
              ? kesatuanIcons[satuan]
              : "";
            return { ...item, icon };
          });
        })
        .catch((error) => {
          console.log("KESATUAN ERROR : ", error.response || error);
        });
    },
  },
  async mounted() {
    this.getData();
  },
};
</script>

<style></style>
