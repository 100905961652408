// We haven't added icon's computed property because it makes this mixin coupled with UI
export const formatNumberSeparator = {
  methods: {
    formatNumberSeparator(val) {
      if (val) {
        return String(val).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1.");
      }
      return val;
    },
  },
}

export const _ = null
