<template>
  <b-card
    no-body
    class="card-statistics"
  >
    <b-card-header>
      <b-card-title>Kondisi personil</b-card-title>
    </b-card-header>
    <b-card-body class="statistics-body">
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.icon"
          md="4"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside

              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    analytics: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      statisticsItems: [
        {
          icon: 'SmileIcon',
          color: 'light-success',
          title: '0',
          subtitle: 'Sehat',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'MehIcon',
          color: 'light-primary',
          title: '0',
          subtitle: 'Kurang Fit',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'FrownIcon',
          color: 'light-danger',
          title: '0',
          subtitle: 'Sakit',
          customClass: 'mb-2 mb-sm-0',
        },
      ],
    }
  },
  watch: {
    analytics: {
      handler(newVal, oldVal) {
        if (newVal) {
          this.setData();
        }
      },
    },
  },
  methods: {
    setData() {
      this.statisticsItems[2].title = this.analytics[2]
      this.statisticsItems[0].title = this.analytics[0]
      this.statisticsItems[1].title = this.analytics[1]
    }
  }
}
</script>
