<template>
  <b-card no-body class="card-employee-task">
    <b-card-header v-if="type === 'new'">
      <b-card-title>Kejadian Terbaru</b-card-title>
    </b-card-header>
    <b-card-header v-else>
      <b-card-title>Pengirim Terbanyak</b-card-title>
    </b-card-header>

    <!-- body -->
    <b-card-body>
      <div
        v-for="(item, index) in kejadian"
        :key="index"
        class="employee-task d-flex justify-content-between align-items-center"
      >
        <b-media no-body>
          <b-media-aside class="mr-75">
            <b-avatar
              rounded
              size="42"
              :src="item.photos === '' ? null : item.photos"
            />
          </b-media-aside>
          <b-media-body class="my-auto">
            <h6 class="mb-0">
              {{ item.nama }}
            </h6>
            <small>{{ item.keterangan }}</small>
            <div v-if="type === 'new'">{{ item.judul }}</div>
          </b-media-body>
        </b-media>
        <div v-if="type === 'new'" class="d-flex" style="align-self: start;">
          <small class="text-muted mr-75">{{ item.waktu }}</small>
        </div>
        <div v-else-if="type === 'many'" class="d-flex">
          <b-badge class="mr-75" variant="primary">
            <small>{{ item.jumlah }} Kejadian</small>
          </b-badge>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardHeader,
  BCardBody,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
  BBadge,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BBadge,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    kejadian: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
};
/* eslint-disable global-require */
</script>
