export const kesatuanIcons = {
  "PIMPINAN": "/assets/kesatuan/pimpinan.png",
  "ROLOG": "/assets/kesatuan/rolog.png",
  "BIDTIK": "/assets/kesatuan/bidtik.png",
  "SAMAPTA": "/assets/kesatuan/samapta.png",
  "POLAIRUD": "/assets/kesatuan/polairud.png",
  "SATBRIMOB": "/assets/kesatuan/satbrimob.png",
  "ITWASDA": "/assets/kesatuan/itwasda.png",
  "ROOPS": "/assets/kesatuan/roops.png",
  "RORENA": "/assets/kesatuan/rorena.png",
  "ROSDM": "/assets/kesatuan/rosdim.png",
  "ROSARPRAS": "/assets/kesatuan/rosarpras.png",
  "BIDPROPAM": "/assets/kesatuan/bidpropam.png",
  "BIDHUMAS": "/assets/kesatuan/bidhumas.png",
  "BIDKUM": "/assets/kesatuan/bidkum.png",
  "SPRIPIM": "/assets/kesatuan/spripim.png",
  "SETUM": "/assets/kesatuan/setum.png",
  "YANMA": "/assets/kesatuan/yanma.png",
  "SPKT": "/assets/kesatuan/spkt.png",
  "DITINTELKAM": "/assets/kesatuan/ditintelkam.png",
  "DITRESKRIMUM": "/assets/kesatuan/ditreskrimum.png",
  "DITRESKRIMSUS": "/assets/kesatuan/ditreskrimsus.png",
  "DITRESNARKOBA": "/assets/kesatuan/ditresnarkoba.png",
  "DITBINMAS": "/assets/kesatuan/ditbinmas.png",
  "DITSABHARA": "/assets/kesatuan/ditsabhara.png",
  "DITLANTAS": "/assets/kesatuan/ditlantas.png",
  "DITPAMOBVIT": "/assets/kesatuan/ditpamobvit.png",
  "DITPOLAIR": "/assets/kesatuan/ditpolair.png",
  "DITTAHTI": "/assets/kesatuan/dittahti.png",
  "SPN": "/assets/kesatuan/spn.png",
  "BIDKEU": "/assets/kesatuan/bidkeu.png",
  "BIDDOKKES": "/assets/kesatuan/biddokkes.png"
}
