import Vue from 'vue'
import App from './App.vue'
import store from './store'

//Router
import router from './router'

import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

require('./bootstrap.js')

import DatePicker from 'vue2-datepicker'
// import BootstrapVue from 'bootstrap-vue'
import PhosporIcon from 'phosphor-vue'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import VueNoty from 'vuejs-noty'

// Global Components
import './global-components'

// Style 
// import './scss/app.scss' 
import 'vue2-datepicker/index.css'; 
import 'vue2-datepicker/locale/id';
import 'vuejs-noty/dist/vuejs-noty.css'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')


Vue.config.productionTip = false

// Vue.use(BootstrapVue)
Vue.use(DatePicker)
Vue.use(PhosporIcon)
Vue.use(PerfectScrollbar)

Vue.use(VueNoty,  {
    theme: 'relax',
    timeout: 2000,
    progressBar: true,
    layout: 'topRight',
    visibilityControl: true
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
