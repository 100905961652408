<template>
  <b-card class="text-center">
    <b-img :src="logo" width="58px" />
    <div class="truncate">
      <h3 class="mb-25 mt-2 font-weight-bolder">
        {{ title }}
      </h3>
    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar, BImg } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
  },
  props: {
    logo: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
  },
}
</script>
