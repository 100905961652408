<template>
  <b-card no-body v-if="analytics.type === 'pie'">
    <b-card-header>
      <div>
        <b-card-title class="mb-1">{{ title }}</b-card-title>
        <b-card-sub-title
          >({{ formatTanggal(analytics.statistikTanggal[0]) }} s/d
          {{ formatTanggal(analytics.statistikTanggal[1]) }})</b-card-sub-title
        >
      </div>
      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <date-picker
          v-model="analytics.statistikTanggal"
          range
          class="custom-date"
          :clearable="false"
          @change="setFilterDate"
        ></date-picker>
      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <!-- apex chart -->
      <vue-apex-charts
        :type="analytics.type"
        height="250"
        class="mt-2 mb-1"
        :options="analytics.chartOptions"
        :series="analytics.series"
      />

      <!-- list group -->
      <div class="pt-25">
        <div
          v-for="(item, index) in analytics.arrData"
          :key="index"
          class="d-flex justify-content-between mb-1"
        >
          <div class="series-info">
            <feather-icon
              icon="TargetIcon"
              size="16"
              class="mr-50"
              :color="analytics.chartOptions.colors[index]"
            />
            <span class="font-weight-bolder">{{ item.name }}</span>
          </div>
          <span>{{ formatNumberSeparator(item.value) }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BCardBody,
  BCardSubTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { format, formatISO, parseISO, add, sub, startOfMonth } from "date-fns";
import id from "date-fns/locale/id";
import { formatNumberSeparator } from "@core/mixins/formatSeparator";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BRow,
    BCardText,
    BCardTitle,
    BCol,
    BCardBody,
    BCardSubTitle,
  },
  mixins: [formatNumberSeparator],
  props: {
    title: {
      type: String,
      default: "",
    },
    analytics: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  methods: {
    formatTanggal(tanggal) {
      return format(parseISO(formatISO(tanggal)), "d MMMM yyyy", {
        locale: id,
      });
    },
    setFilterDate(){
      this.$emit('result', this.analytics.statistikTanggal)
    }
  },
};
</script>
