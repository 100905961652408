import { HOME_DATA, PERSONIL_AKTIF_DATA, PERSONIL_BEAT_AKTIF_DATA } from './const'
import client from './client'
import { formatDate } from './shared'

const home = async () => {
  const { data } = await client.get(HOME_DATA)
  return data
}

export const personilAktif = async (startdate, enddate) => {
  const { data } = await client.get(PERSONIL_AKTIF_DATA, {
    params: {
      startdate: formatDate(startdate), enddate: formatDate(enddate)
    }
  })
  return data
}

export const personilBeatAktif = async (startdate, enddate) => {
  const { data } = await client.get(PERSONIL_BEAT_AKTIF_DATA, {
    params: {
      startdate: formatDate(startdate), enddate: formatDate(enddate)
    }
  })
  return data
}

export default home
