import { DATA_KEGIATAN, DATA_KEGIATAN_CHART_JENIS, DATA_KEGIATAN_CHART_STATISTIK } from './const'
import client from './client'
import { formatDate } from './shared'

export const kegiatan = async () => {
  const { data } = await client.get(DATA_KEGIATAN)
  return data
}

export const kegiatanChartStatistik = async (start, end) => {
  const startdate = formatDate(start), enddate = formatDate(end)
  const { data } = await client.post(DATA_KEGIATAN_CHART_STATISTIK, { startdate, enddate })
  return data
}

export const kegiatanChartJenis = async (start, end) => {
  const startdate = formatDate(start), enddate = formatDate(end)
  const { data } = await client.post(DATA_KEGIATAN_CHART_JENIS, { startdate, enddate })
  return data
}

