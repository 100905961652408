const apiBaseUrl = process.env.VUE_APP_API_BASE_URL

export const AUTH_SIGNIN = `${apiBaseUrl}/user/auth`
export const AUTH_DETAIL = `${apiBaseUrl}/user/`

export const BASE = `${apiBaseUrl}/executive`
export const HOME_DATA = `${BASE}/home`

export const PERSONIL_BEAT_AKTIF_DATA = `${BASE}/personil_beat_aktif`

export const PERSONIL_AKTIF_DATA = `${BASE}/personil_aktif`

export const DATA_JAJARAN = `${BASE}/jajaran`
export const DATA_KESATUAN = `${BASE}/kesatuan`
export const DATA_PERSONIL = `${BASE}/personil`

export const BASE_KEGIATAN = `${BASE}/kegiatan`
export const DATA_KEGIATAN = `${BASE_KEGIATAN}`
export const DATA_KEGIATAN_CHART_STATISTIK = `${BASE_KEGIATAN}/chart/statistik`
export const DATA_KEGIATAN_CHART_JENIS = `${BASE_KEGIATAN}/chart/jenis`

export const BASE_DARURAT = `${BASE}/darurat`
export const DATA_DARURAT = `${BASE_DARURAT}`
export const DATA_DARURAT_CHART_STATISTIK = `${BASE_DARURAT}/chart/statistik`
export const DATA_DARURAT_CHART_STATUS = `${BASE_DARURAT}/chart/status`

export const BASE_PENGADUAN = `${BASE}/pengaduan`
export const DATA_PENGADUAN = `${BASE_PENGADUAN}`
export const DATA_PENGADUAN_CHART_STATISTIK = `${BASE_PENGADUAN}/chart/statistik`

export const BASE_KEJADIAN = `${BASE}/kejadian`
export const DATA_KEJADIAN = `${BASE_KEJADIAN}`
export const DATA_KEJADIAN_CHART_STATISTIK = `${BASE_KEJADIAN}/chart/statistik`
export const DATA_KEJADIAN_CHART_STATUS = `${BASE_KEJADIAN}/chart/status`
