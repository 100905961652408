import Vue from "vue";
import Router from "vue-router";


import Login from "@/views/Login";
import Dashboard from "@/views/Dashboard";
import Darurat from "@/views/MasterData/Darurat";
import Jajaran from "@/views/MasterData/Jajaran";
import Kegiatan from "@/views/MasterData/Kegiatan";
import Kejadian from "@/views/MasterData/Kejadian";
import Personil from "@/views/MasterData/Personil";
import Satker from "@/views/MasterData/Satker";
// import ExecutiveDashboard from "@/views/Executive/Dashboard/Main";
// import Executive from "@/views/Executive/Main";
// import ExecutiveLogin from "@/views/Executive/Login/Main";
// import ExecutiveKegiatan from "@/views/Executive/Kegiatan/Main";
// import ExecutivePengaduan from "@/views/Executive/Pengaduan/Main";
// import ExecutiveKejadian from "@/views/Executive/Kejadian/Main";
// import ExecutiveDarurat from "@/views/Executive/Darurat/Main";
// import ExecutiveKesatuan from "@/views/Executive/Kesatuan/Main";
// import ExecutiveJajaran from "@/views/Executive/Jajaran/Main";
// import ExecutivePersonil from "@/views/Executive/Personil/Main";

Vue.use(Router);

const router = new Router({
  mode: "hash",
  routes: [
    {
      path: "/",
			name: "dashboard",
      component: Dashboard,
    },
		{
			path: '/MasterData/Darurat',
			name: 'Darurat | Executive',
			component: Darurat,
			meta: {
				pageTitle: 'Darurat',
				breadcrumb: [
					{
						text: 'Master Data',
					},
					{
						text: 'Darurat',
						active: true,
					},
				],
			},
		},
		{
			path: '/MasterData/Jajaran',
			name: 'Polres Jajaran | Executive',
			component: Jajaran,
			meta: {
				pageTitle: 'Jajaran',
				breadcrumb: [
					{
						text: 'Master Data',
					},
					{
						text: 'Jajaran',
						active: true,
					},
				],
			},
		},
		{
			path: '/MasterData/Kegiatan',
			name: 'Kegiatan | Executive',
			component: Kegiatan,
			meta: {
				pageTitle: 'Kegiatan',
				breadcrumb: [
					{
						text: 'Master Data',
					},
					{
						text: 'Kegiatan',
						active: true,
					},
				],
			},
		},
		{
			path: '/MasterData/Kejadian',
			name: 'Kejadian | Executive',
			component: Kejadian,
			meta: {
				pageTitle: 'Kejadian',
				breadcrumb: [
					{
						text: 'Master Data',
					},
					{
						text: 'Kejadian',
						active: true,
					},
				],
			},
		},
		{
			path: '/MasterData/Personil',
			name: 'Personil | Executive',
			component: Personil,
			meta: {
				pageTitle: 'Personil',
				breadcrumb: [
					{
						text: 'Master Data',
					},
					{
						text: 'Personil',
						active: true,
					},
				],
			},
		},
		{
			path: '/MasterData/Kesatuan',
			name: 'Satker | Executive',
			component: Satker,
			meta: {
				pageTitle: 'Satker',
				breadcrumb: [
					{
						text: 'Master Data',
					},
					{
						text: 'Satker',
						active: true,
					},
				],
			},
		},
		// {
    //   path: "/dash",
    //   component: Executive,
    //   children: [
    //     {
    //       path: "",
    //       name: "Dashboard",
    //       component: ExecutiveDashboard,
    //     },
    //     {
    //       path: "kegiatan",
    //       name: "Kegiatan | Executive",
    //       component: ExecutiveKegiatan,
    //     },
    //     {
    //       path: "pengaduan",
    //       name: "Pengaduan | Executive",
    //       component: ExecutivePengaduan,
    //     },
    //     {
    //       path: "kejadian",
    //       name: "Kejadian | Executive",
    //       component: ExecutiveKejadian,
    //     },
    //     {
    //       path: "darurat",
    //       name: "Darurat | Executive",
    //       component: ExecutiveDarurat,
    //     },
    //     {
    //       path: "kesatuan",
    //       name: "Satker | Executive",
    //       component: ExecutiveKesatuan,
    //     },
    //     {
    //       path: "jajaran",
    //       name: "Polres Jajaran | Executive",
    //       component: ExecutiveJajaran,
    //     },
    //     {
    //       path: "personil",
    //       name: "Personil | Executive",
    //       component: ExecutivePersonil,
    //     },
    //   ],
    // },
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: {
        layout: "full",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  document.title = to.name + " | " + appName;
  next();
});

export default router;
